<template>
  <div class="page-content n-p pt-5 pb-0 mb-0">
    <MetaCards :title="$t('seo.loginTitle')" :description="$t('seo.loginDescription')" :disable-keywords="true" />
    <structured-data-webpage :name="$t('seo.loginTitle')" :description="$t('seo.loginDescription')" />
    <div class="container">
      <div class="row justify-content-center pb-5 mt-5">
        <div class="col-md-8 col-lg-6">
          <div class="card overflow-hidden">
            <div class="bg-soft-primary auth-block">
              <div class="row">
                <div class="col-sm-7">
                  <div class="text-primary p-4">
                    <h4 class="text-troov-primary-dark title-font">
                      {{ $t('common.connect') }}
                    </h4>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div class="d-none d-sm-block col-sm-5 align-self-end">
                  <b-img src="/images/profile-img.png" alt class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <NuxtLink :to="localePath('login')" :aria-label="$t('login.login_redirect')">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <t-icon-shield-lock font-scale="3" variant="dark" />
                    </span>
                  </div>
                </NuxtLink>
              </div>

              <b-alert v-model="errorMsg" variant="danger" class="mt-3" dismissible>
                {{ $t('messages.connection_error') }} : {{ $t(errorMessageText) }}
              </b-alert>

              <div v-if="displayLogin">
                <login-form :login="login" :disabled="disabledLogin" :require-captcha="showCaptcha" />
              </div>
              <hr v-if="displayLogin && displaySso" />
              <div v-if="displaySso" class="text-center mt-2">
                <a v-for="provider in providersList" :key="provider.logo" :href="provider.url" class="btn btn-outline-dark plain">
                  <b-img-lazy :src="provider.logo" alt="" height="35px" />
                  {{ provider.text }}
                </a>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <!-- end row -->
        </div>
        <!-- end col -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoginForm from '~/components/common/forms/login-form';
import MetaCards from '~/components/seo/meta-cards';
import TIconShieldLock from '~/components/common/icons/t-icon-shield-lock';
import StructuredDataWebpage from '~/components/seo/structured-data-web-page';

export default {
  components: {
    StructuredDataWebpage,
    TIconShieldLock,
    'login-form': LoginForm,
    MetaCards
  },
  layout: 'troov/meae',
  data() {
    const $t = this.$t.bind(this);
    return {
      email: '',
      password: '',
      errorMessageText: '',
      displayLogin: true,
      displaySso: false,
      errorMsg: false,
      showCaptcha: false,
      disabledLogin: false,
      displayProvidersList: false,
      providersList: [
        {
          url: `${this.$config.API_ROOT}/api/auth/consulat`,
          logo: '/img/partners/logo-marianne.png',
          text: $t('common.auth_meae')
        }
      ]
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token
    })
  },
  created() {
    const query = this.$router.currentRoute.query || {};
    this.errorMsg = !!query.errorMsg;
    this.displaySso = (query.mode || '').toLowerCase() === 'meae' || this.$config.environment === 'production';
    this.displayLogin = !this.displaySso;
    if (query.auto && typeof window !== 'undefined') {
      // Redirect auth MEAE
      window.location.replace(this.providersList[0].url);
    }
  },
  methods: {
    login(ctx) {
      this.$root.$loading.start();
      this.disabledLogin = true;
      this.$store
        .dispatch('user/login', {
          email: ctx.email,
          password: ctx.password,
          captcha: ctx.captcha
        })
        .then((user) => {
          this.disabledLogin = false;
          this.$root.$loading.finish();
          if (this.$router.currentRoute.query && this.$router.currentRoute.query.nextLink && !this.$router.currentRoute.query.noRetry) {
            let uri = decodeURI(this.$router.currentRoute.query.nextLink);
            uri = uri + (uri.includes('?') ? '&' : '?') + 'noRetry=1';
            this.$router.push(uri + this.$router.currentRoute.hash);
          } else if (user.hasTotp) {
            this.$router.push(`/${user.language || this.$i18n.locale}/login-totp`);
          } else {
            const team = user.member.team;
            this.$store.commit('user/SET_USER', user);
            this.$router.push(this.localePath({ name: 'dashboard-team-reservations', params: { team: team._id } }));
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 429) {
            this.showCaptcha = true;
          }
          if (!this.showCaptcha && err.response && err.response) {
            this.showCaptcha = err.response.headers['x-gouv-limit'] > 3;
          }
          this.errorMsg = true;
          const errMsg = (err.response && err.response.data && err.response.data.message) || 'Arf';
          switch (errMsg) {
            case 'Account blocked':
              this.errorMessageText = 'messages.login_account_blocked';
              break;
            case 'ACCOUNT_BLOCKED':
              this.errorMessageText = 'messages.login_account_blocked';
              break;
            case 'Account deleted':
              this.errorMessageText = 'messages.login_deleted_user';
              break;
            case 'ACCOUNT_DELETED':
              this.errorMessageText = 'messages.login_deleted_user';
              break;
            default:
              this.errorMessageText = 'messages.log_error';
          }

          this.disabledLogin = false;
          this.$root.$loading.finish();
        });
    }
  }
};
</script>
