<template>
  <div>
    <b-form method="post" class="p-2" @submit.prevent="submit">
      <b-form-group id="email-group" :label="$t('common.email')" label-for="email">
        <b-form-input id="email" ref="email" v-model="email" name="email" type="email" required :disabled="disabled" autocomplete="off" />
      </b-form-group>

      <b-form-group id="password-group" :label="$t('common.password')" label-for="password">
        <b-input-group>
          <b-form-input
            id="password"
            ref="password"
            v-model="password"
            name="password"
            :type="seePassword ? 'text' : 'password'"
            required
            :disabled="disabled"
            class="border-right-0"
            @paste.prevent.stop
          />
          <b-input-group-append>
            <b-button
              variant="light"
              :disabled="disabled"
              class="border-left-0 bg-transparent"
              style="border-width: 1px"
              @click="seePassword = !seePassword"
            >
              <t-icon :icon="`eye-${seePassword ? 'slash' : 'fill'}`" :font-scale="1"></t-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-collapse :visible="requireCaptcha">
        <b-form-group id="is-human" :label="$t('confirmation.i_am_human') + '*'" label-for="is-human-input" class="mt-4">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <div :style="captchaStyle" />
            <div>
              <b-button variant="light" size="lg" @click="loadCaptcha">
                <i class="bx bx-refresh bx-sm align-middle"></i>
              </b-button>
            </div>
          </div>
          <b-form-input
            id="is-human-input"
            ref="humanInput"
            v-model="captchaInput"
            name="is-human-input"
            class="form-control"
            :placeholder="$t('confirmation.i_am_not_human_notice')"
            :state="isValidCaptcha"
            autocomplete="off"
            @paste.prevent.stop
            @change="isValidCaptcha = null"
          />
          <b-form-invalid-feedback id="input-live-feedback">
            {{ $t('confirmation.i_am_not_human_notice_error') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-collapse>
      <div class="mt-4">
        <b-button type="submit" variant="primary" class="btn-block" :disabled="disabled">
          {{ !!captchaImage ? $t('common.connection') : $t('common.next_step') }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import TIcon from '~/components/common/icons/t-icon';
import CaptchaMixins from '~/helpers/captchaMixins';

export default {
  components: { TIcon },
  mixins: [CaptchaMixins],
  props: {
    login: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    requireCaptcha: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: '',
      password: '',
      seePassword: false
    };
  },
  methods: {
    submit() {
      if (this.requireCaptcha && (!this.captchaInput || `troov_c_${this.captchaInput}`.toLowerCase() !== `${this.captcha}`.toLowerCase())) {
        this.isValidCaptcha = false;
        this.loadCaptcha();
        return this.$toastError(this.$t('confirmation.i_am_not_human_notice_error'));
      }
      if (this.login) {
        this.login({ ...this, captcha: `troov_c_${this.captchaInput}` });
      }
    }
  }
};
</script>
<style scoped>
.btn-primary:hover {
  background-color: #1212ff !important;
}
</style>
