import { mapGetters } from 'vuex';

const CaptchaMixins = {
  data() {
    return {
      captchaInput: null,
      captchaImage: null,
      isValidCaptcha: null,
      captchaAudio: null
    };
  },
  computed: {
    ...mapGetters('user', ['captcha', '_csrf']),
    captchaStyle() {
      if (this.captchaImage) {
        return {
          width: '300px',
          height: '90px',
          'background-image': `url(${this.captchaImage})`,
          'background-size': 'contain',
          'background-repeat': 'no-repeat',
          'background-position': 'center'
        };
      }
      return null;
    }
  },
  mounted() {
    this.loadCaptcha();
  },
  methods: {
    loadCaptcha() {
      return this.$store.dispatch('user/handshake').then(() => {});
    },
    playAudio() {
      const player = new Audio('data:audio/mp3;base64,' + this.captchaAudio);
      player.play();
    }
  }
};

export default CaptchaMixins;
